<div
  class="flex gap-4 py-3 px-4 items-center rounded-xl text-sm"
  [ngClass]="[
    type === 'info' ? 'text-blue-500 bg-blue-50' : '',
    type === 'warning' ? 'text-amber-600 bg-amber-100' : '',
    type === 'danger' ? 'text-red-500 bg-red-50' : '',
    type === 'success' ? 'text-lime-600 bg-lime-50' : ''
  ]"
>
  <ng-content></ng-content>
</div>
