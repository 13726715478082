<ui-cell-page-header
  [heading]="'Profil - ' + (partyService.person$ | async)?.firstName + ' ' + (partyService.person$ | async)?.lastName"
  [subheading]="(partyService.person$ | async)?.email + ''"
></ui-cell-page-header>

<div class="grid grid-cols-1 gap-4 md:gap-8 2xl:grid-cols-12" *ngIf="partyService.person$ | async as _person">
  <tx-portal-profile-card
    class="2xl:col-span-9"
    [person$]="partyService.person$"
    [session$]="partyService.session$"
    [otpCredentials$]="partyService.otpCredentials$.asObservable()"
    [currentTenant$]="partyService.currentTenant$"
    (saveProfile)="onSaveProfile($event)"
    (resetPassword)="partyService.reset()"
    (configureTotp)="partyService.configureTotp()"
    (updateNotifcationSettings)="partyService.updatePersonNotificationSettings(_person, $event)"
  ></tx-portal-profile-card>
  <tx-portal-sessions-card
    *ngIf="partyService.sessions$"
    [sessions$]="partyService.sessions$"
    (deleteCurrentSession)="partyService.deleteSession()"
    class="2xl:col-span-3"
  ></tx-portal-sessions-card>
</div>
