import { Injectable } from '@angular/core';
import {
  Company,
  CompanyFeatures,
  ContactPerson,
  Organisation,
  PeopleQuery,
  PeopleQueryVariables,
  Person,
  TxApi
} from '@tx/api';

import { OAuthService, AuthConfig, OAuthEvent } from 'angular-oauth2-oidc';
import { QueryRef } from 'apollo-angular/query-ref';
import { BehaviorSubject, from, lastValueFrom, Observable, Observer, ReplaySubject, throwError } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { map, switchMap, delay, tap, filter, first, pairwise, distinctUntilChanged } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, retry, share, shareReplay } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from '@apollo/client/utilities';

import { NotificationService } from '../../../../../libs/ui/src/lib/notification/notification.service';
import { DeleteOnePersonPerson, UpdatePersonRoles } from '../account-management/account-management.component';
import { AddBillingAddressOutput } from '../services/service-detail/service-contract/service-contract.component';
import { CreatePersonOutput } from '../account-management/create-person/create-person.component';
import {
  AddPersonAsContactPerson,
  CreatePersonAsContactPerson,
  EditPersonAsContactPerson
} from '../services/contact/contact.component';
import { CreateAndAddServiceUser } from '../shared/models';
const SCOPE = 'openid profile roles username email offline_access aud';
export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.oidc.issuer,
  redirectUri: window.location.origin + window.location.pathname,
  clientId: environment.oidc.clientId,
  postLogoutRedirectUri: window.location.origin + window.location.pathname,
  useIdTokenHintForSilentRefresh: true,
  //dummyClientSecret: environment.oidc.clientSecret,
  responseType: 'code',
  useSilentRefresh: true,
  scope: SCOPE,
  showDebugInformation: true,
  sessionChecksEnabled: true
};
function trim(s: string, c: string) {
  if (c === ']') c = '\\]';
  if (c === '^') c = '\\^';
  if (c === '\\') c = '\\\\';
  return s.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '');
}
export type UserCredentialMetadatas = {
  credential: {
    id: string;
    type: 'otp' | 'password';
    userLabel: string;
    createdDate: string;
  };
};
export type OtpCredential = {
  type: 'otp' | 'password' | 'none';
  category: 'basic-authentication' | 'two-factor';
  createAction: 'CONFIGURE_TOTP' | 'UPDATE_PASSWORD';
  removeable: boolean;
  userCredentialMetadatas: UserCredentialMetadatas[];
};

type JwtToken = {
  sub: string;
  sid: string;
  email_verified: boolean;
  tenants: string[];
  companies: any[];
  fulltenants: string[];
};

@Injectable({
  providedIn: 'root'
})
export class PartyService {
  constructor(
    private oauthService: OAuthService,
    private txApi: TxApi,
    private http: HttpClient,
    private apollo: Apollo,
    private notificationService: NotificationService
  ) {
    this.oauthService.configure(authCodeFlowConfig);
    this.personQuery = this.txApi.peopleWatch();

    this.initEventHub();
    this.initAuth();
  }

  personQuery!: QueryRef<PeopleQuery, PeopleQueryVariables>;

  personUpdateMutation!: any;

  // emits the current Person object of the logged in user
  person$!: Observable<Person>;
  people$!: Observable<Person[]>;

  companies$!: Observable<Company[]>;

  organisation$!: Observable<Organisation>;
  organisations$!: Observable<Organisation[]>;

  // emits the current Person object of the logged in user
  sessions$!: Observable<any>;

  otpCredentials$: BehaviorSubject<OtpCredential[]> = new BehaviorSubject<OtpCredential[]>([]);

  // validates is a valid access_token is available
  isAuthenticated$!: Observable<boolean>;

  // checks for enabled Features on the Company
  enabledFeatures$!: Observable<CompanyFeatures>;

  // if the user logged in via a company:company_id scope the current tenant is available
  currentTenant$!: Observable<Company | undefined>;

  // the current userInfo (Keycloak ID Token)
  session$!: Observable<any>;
  isAdministrator$!: Observable<boolean>;

  isCompanySelected = false;
  companiesLoadedLength = 0;

  oauth$ = new ReplaySubject<JwtToken>(1);

  async initFlow() {
    const loginResult = await this.oauthService.loadDiscoveryDocumentAndLogin();
    this.oauthService.setupAutomaticSilentRefresh();
    const identityClaims = this.oauthService.getIdentityClaims() as JwtToken;

    this.oauth$.next(identityClaims);
  }
  async initAuth() {
    this.initFlow();
    const oauth$ = this.oauth$.asObservable();

    //;
    this.oauthService.events.pipe(filter((data: any) => data.info !== null)).subscribe((event) => {
      //console.log(event);
    });
    //register Error Handler logic
    this.oauthService.events
      .pipe(
        filter((data: any) => data.info !== null),
        filter((e: any) => e.type === 'invalid_nonce_in_state')
      )
      .subscribe(() => {
        // invalid_nonce_in_state is a racecondition ans solved be retrying
        this.oauthService.initLoginFlow();
      });

    oauth$.pipe(filter((data) => data !== null)).subscribe((identityClaims) => {
      // if the user is not verified we try this here.
      this.person$.pipe(first()).subscribe((pers) => {
        if (!identityClaims.email_verified || !pers.isKeycloakVerified) {
          this.verifyKeycloakUser(pers);
        }
      });
    });

    // this is the logged in Account from us not keycloak
    this.person$ = oauth$.pipe(
      filter((data) => {
        return data !== null;
      }),
      switchMap((identityClaims) => {
        console.log(identityClaims);

        return this.personQuery.valueChanges.pipe(
          map(({ data, loading }) => {
            return data.people.edges.map((n) => n.node);
          }),
          map(cloneDeep),
          map((data) => {
            let pers = data.find((person) => person.keycloakUser === identityClaims.sub) as Person;
            if (!pers) {
              this.devError(
                `Most likely there is no Person with sub id ${identityClaims.sub}`,
                'No User matches Keycloak Sub'
              );
            }

            return pers;
          })
        );
      }),
      shareReplay()
    );

    // all the companies we have access to
    this.companies$ = this.person$.pipe(
      map((person) => {
        this.companiesLoadedLength = person.companies.length;
        return person.companies;
      })
    );

    // all accounts we have access to

    this.people$ = oauth$.pipe(
      filter((data) => data !== null),
      switchMap((identityClaims) => {
        return this.personQuery.valueChanges.pipe(
          map(({ data, loading }) => {
            return data.people.edges.map((n) => n.node) as Person[];
          })
        );
      }),
      shareReplay()
    );

    // the current sessions from the keycloak proxy api
    this.sessions$ = oauth$.pipe(
      filter((data) => data !== null),
      switchMap((identityClaims) => {
        return this.http.get(`${environment.oidc.issuer}/account/sessions/devices`, {
          headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${this.oauthService.getAccessToken()}`
          }
        });
      }),
      shareReplay()
    );
    this.session$ = oauth$;

    this.loadCredentials();

    // the current session -> decoded IDToken

    // the current tenant is the dynamic scope from company:* and must be included in the tenant scope
    this.currentTenant$ = oauth$.pipe(
      filter((data) => data !== null),
      switchMap((identityClaims) => {
        return this.person$.pipe(
          map((person) => {
            if (!person) {
              this.devError(`Most likely TxX-Api user does not map to keycloak user`, 'User does not match!');
            }
            const scope = this.oauthService.getGrantedScopes() as string[];
            if (identityClaims.companies.length < 1) {
              this.devError('Keycloak user has no companies assigned', 'Missing Company');
            }
            const tenants = Object.keys(identityClaims.companies);

            for (const tenant of tenants) {
              if (scope.includes(`company:${person.companies.find((c) => c.keycloakId == tenant)?.keycloakId}`)) {
                this.isCompanySelected = true;
                return person.companies.find((c) => c.keycloakId == tenant);
              }
            }
            if (person.companies.length === 1) {
              this.login(person.companies[0].keycloakId);
            }
            return;
          })
        );
      }),
      shareReplay()
    );

    // Load the Company Features
    this.enabledFeatures$ = this.currentTenant$.pipe(
      map((comapny) => comapny?.features || {}),
      shareReplay()
    );

    // the current organisation - matched via tenant=company and recieved by the company->organisation relation
    this.organisations$ = oauth$.pipe(
      filter((data) => data !== null),
      switchMap((identityClaims) => {
        return this.currentTenant$.pipe(
          switchMap((tenant) => {
            if (!tenant) {
              throw new Error('No Tenant selected');
            }

            return this.personQuery.valueChanges.pipe(
              map(({ data, loading }) => {
                return data.people.edges.map((n) => n.node);
              }),
              map((data) => {
                let pers = data.find((person) => person.keycloakUser === identityClaims.sub) as Person;
                return pers;
              }),
              map(cloneDeep),
              map((data) => {
                return data.companies.find((c) => c.name === tenant.name)?.organisations as Organisation[];
              })
            );
          })
        );
      }),
      shareReplay()
    );

    // checks the keycloak for validity
    this.isAuthenticated$ = of(this.oauthService.hasValidAccessToken());

    // checks assigned roles for administrator privileges
    this.isAdministrator$ = oauth$.pipe(
      filter((data) => data !== null),
      switchMap((identityClaims) => {
        return this.person$.pipe(
          map((tenant) => {
            if (!identityClaims.tenants) return false;
            return identityClaims.tenants.includes(`Account Administrator`);
          })
        );
      }),
      shareReplay()
    );
    this.watchLongPollingPeople();
  }

  watchLongPollingPeople() {
    this.people$
      .pipe(
        pairwise(), // Pair the previous and current values
        filter(([previousValue, currentValue]) => {
          return currentValue.length !== previousValue.length;
        }),
        map(() => {
          this.personQuery.stopPolling();
        })
      )
      .subscribe();
  }

  loadCredentials() {
    this.session$
      .pipe(
        switchMap((identityClaims) =>
          this.http
            .get<OtpCredential[]>(`${environment.oidc.issuer}/account/credentials`, {
              headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${this.oauthService.getAccessToken()}`
              }
            })
            .pipe(map((data) => data.filter((c) => c.type === 'otp')))
        ),
        shareReplay()
      )
      .subscribe((d) => this.otpCredentials$.next(d));
  }

  devError(message: string, title: string = 'Notification', severity: string = 'error') {
    if (window.location.hostname !== 'my.telemaxx.de') {
      this.notificationService.showError(`[DEV] ${title}`, message, 3600 * 100);
    }
  }

  initEventHub() {
    //this.oauthService.events.subscribe((event) => console.log(event));
  }

  deleteSession() {
    return this.http
      .delete(`${environment.oidc.issuer}/account/sessions`, {
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${this.oauthService.getAccessToken()}`
        }
      })
      .subscribe((d) => {
        this.notificationService.showSuccess('Erfolg!', `Session wurde gelöscht`);
      });
  }

  accountView() {}

  login(scope: string = '') {
    scope = trim(scope, '\n');
    if (scope) {
      this.oauthService.scope = `${SCOPE.replace(' company:*', '')} company:${scope}`;
    }
    this.oauthService.redirectUri = window.location.origin + window.location.pathname;
    this.oauthService.initCodeFlow();
  }

  // for dev, tokens are auto refreshed
  refresh() {
    this.oauthService.refreshToken();
  }

  async reset() {
    await this.oauthService.initLoginFlow('', { kc_action: 'UPDATE_PASSWORD' });
  }

  async configureTotp() {
    await this.oauthService.initLoginFlow('', { kc_action: 'CONFIGURE_TOTP' });
  }

  async deleteOtpDevice(device: UserCredentialMetadatas) {
    return this.http
      .delete<void>(`${environment.oidc.issuer}/account/credentials/${device.credential.id}`, {
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${this.oauthService.getAccessToken()}`
        }
      })
      .subscribe(() => {
        this.loadCredentials();
        this.notificationService.showSuccess('Erfolg!', `2FA Gerät wurde entfernt`);
      });
  }
  async updateOtpDeviceLabel(device: UserCredentialMetadatas, label: string) {
    return this.http
      .put<void>(`${environment.oidc.issuer}/account/credentials/${device.credential.id}/label`, `"${label}"`, {
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${this.oauthService.getAccessToken()}`
        }
      })
      .subscribe((d) => {
        this.loadCredentials();
        this.notificationService.showSuccess('Erfolg!', `2FA Gerät wurde neu benannt`);
      });
  }

  async logout() {
    await this.oauthService.revokeTokenAndLogout();
  }

  updateCompanyOTPSettings() {
    this.txApi.toggleCompanyOtpRequirement().subscribe((data) => {
      if (!data.loading) {
        if (data.data?.toggleCompanyOTPRequirement.isOtpRequired) {
          this.notificationService.showSuccess('Erfolg!', `2FA wurde Firmenweit aktiviert`);
        }
        if (!data.data?.toggleCompanyOTPRequirement.isOtpRequired) {
          this.notificationService.showSuccess('Erfolg!', `2FA wurde Firmenweit deaktiviert`);
        }
        this.personQuery.refetch();
      }
    });
  }

  updatePersonNotificationSettings(person: Person, data: any) {
    this.personUpdateMutation = this.txApi
      .updateOnePerson({
        input: {
          id: person.id,
          update: {
            ...person,
            ...data
          }
        }
      })
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Dein Profil wurde aktualisiert`);
        }
      });
  }

  verifyKeycloakUser(person: Person) {
    this.http
      .post(`${environment.apiBasePath}/party/user/verify`, {
        person
      })
      .subscribe(() => {
        this.personQuery.refetch();
      });
  }
  updatePerson(person: any) {
    this.personUpdateMutation = this.txApi
      .updateOnePerson({
        input: {
          id: person.id,
          update: {
            ...person
          }
        }
      })
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Dein Profil wurde aktualisiert`);
        }
      });
  }
  createOnePerson(createPersonOutput: CreatePersonOutput) {
    let serviceOwnerOf = createPersonOutput.serviceOwnerOf || [];
    this.http
      .post(`${environment.apiBasePath}/party/user/register`, {
        username: createPersonOutput.email,
        ...createPersonOutput
      })
      .subscribe({
        next: (data: Person | any) => {
          if (serviceOwnerOf.length === 0) {
            this.notificationService.showSuccess('Erfolg!', `Benutzer wurde angelegt`);
            this.personQuery.startPolling(3000);
            return;
          }
          this.addServiceOwnerOfToPerson(data.id, serviceOwnerOf);
        },
        error: (error) => {
          this.notificationService.showError('Fehler!', error.error.message);
        }
      });
  }
  updateOneOrganisation(organisation: any) {
    this.txApi
      .updateOneOrganisation({
        input: {
          id: organisation.id,
          update: organisation
        }
      })
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Organisation wurde aktualisiert`);
        }
      });
  }

  upsertOneBillingAddress(event: AddBillingAddressOutput) {
    if (event.invoiceRecipient.id === '') {
      delete event.invoiceRecipient.id;
    }
    this.txApi
      .updateOneServiceInstance(
        {
          input: {
            id: event.id,
            update: event
          }
        },
        {
          refetchQueries: ['serviceInstances']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Rechnungsempfänger wurde aktualisiert`);
        }
      });
  }

  async createOneAddress(organisation: Organisation) {
    const newAddress = {
      city: '',
      street: '',
      postal: '',
      companyName: '',
      email: '',
      firstName: '',
      lastName: '',
      country: '',
      organisationId: organisation.id
    };
    this.txApi
      .createOneAddress(
        {
          input: {
            address: newAddress
          }
        },
        {
          refetchQueries: ['people']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Alternativer Rechnungsempfänger wurde hinzugefügt`);
        }
      });
  }
  removeContactPerson(dto: ContactPerson) {
    this.txApi
      .deleteOneContactPerson(
        {
          input: {
            id: dto.id
          }
        },
        {
          refetchQueries: ['serviceInstances']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess(
            'Erfolg!',
            `Der Kontakt wurde entfernt der Benutzer ist nicht mehr verknüpft`
          );
        }
      });
  }
  addContactPerson(dto: AddPersonAsContactPerson) {
    this.txApi
      .createOneContactPerson(
        {
          contactPerson: {
            contactPersonFor: dto.for,
            personId: dto.id,
            note: dto.note,
            serviceInstanceId: dto.serviceInstanceId
          }
        },
        {
          refetchQueries: ['serviceInstances']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess(
            'Erfolg!',
            `Der Kontakt wurde hinzugefügt und mit dem Benutzer verknüpft`
          );
        }
      });
  }
  editContactPerson(dto: EditPersonAsContactPerson) {
    this.txApi
      .updateOneContactPerson(
        {
          input: {
            id: dto.id,
            update: {
              note: dto.note,
              person: dto.person
            }
          }
        },
        {
          refetchQueries: ['serviceInstances']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Der Kontakt wurde aktalisiert`);
        }
      });
  }
  addContact(dto: CreatePersonAsContactPerson) {
    this.txApi
      .createOneContactPerson(
        {
          contactPerson: {
            contactPersonFor: dto.for,
            note: dto.note,
            serviceInstanceId: dto.serviceInstanceId,
            person: dto.person
          }
        },
        {
          refetchQueries: ['serviceInstances']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Der Kontakt wurde hinzugefügt`);
        }
      });
  }
  addServiceOwnerOfToPerson(personId: string, serviceInstanceIds: string[]) {
    this.txApi
      .addServiceOwnerOfToPerson(
        {
          id: personId,
          serviceInstanceIds: serviceInstanceIds
        },
        {
          refetchQueries: ['serviceInstances', 'people']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Service-Owner wurde hinzugefügt`);
        }
      });
  }
  addServiceUserOfToPerson(personId: string, serviceInstanceIds: string[]) {
    this.txApi
      .addServiceUserOfToPerson(
        {
          id: personId,
          serviceInstanceIds: serviceInstanceIds
        },
        {
          refetchQueries: ['serviceInstances', 'people']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Service-User wurde hinzugefügt`);
        }
      });
  }

  removeServiceOwnerOfToPerson(personId: string, serviceInstanceId: string) {
    this.txApi
      .removeServiceOwnerOfFromPerson(
        {
          id: personId,
          serviceInstanceIds: [serviceInstanceId]
        },
        {
          refetchQueries: ['serviceInstances', 'people']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Service-Owner wurde entfernt`);
        }
      });
  }

  removeServiceUserOfFromPerson(personId: string, serviceInstanceId: string) {
    this.txApi
      .removeServiceUserOfFromPerson(
        {
          id: personId,
          serviceInstanceIds: [serviceInstanceId]
        },
        {
          refetchQueries: ['serviceInstances', 'people']
        }
      )
      .subscribe((data) => {
        if (!data.loading) {
          this.notificationService.showSuccess('Erfolg!', `Service-User wurde entfernt`);
        }
      });
  }

  createInstanceServiceOwner(person: Person, serviceInstanceId: string) {
    this.http
      .post(`${environment.apiBasePath}/party/user/register`, {
        username: person.email,
        email: person.email,
        firstName: person.firstName,
        lastName: person.lastName,
        companyName: person.companyName,
        phone: person.phone,
        mobilePhone: person.mobilePhone,
        gender: person.gender
      })
      .subscribe({
        next: (data: Person | any) => {
          this.addServiceOwnerOfToPerson(data.id, [serviceInstanceId]);
        },
        error: (error) => {
          this.notificationService.showError('Fehler!', error.error.message);
        }
      });
  }

  createPerson(person: Person) {
    return this.http.post(`${environment.apiBasePath}/party/user/register`, {
      username: person.email,
      email: person.email,
      firstName: person.firstName,
      lastName: person.lastName,
      companyName: person.companyName,
      phone: person.phone,
      mobilePhone: person.mobilePhone,
      gender: person.gender
    });
  }
  createServiceUser(dto: CreateAndAddServiceUser) {
    this.http
      .post(`${environment.apiBasePath}/party/user/register`, {
        username: dto.person.email,
        email: dto.person.email,
        firstName: dto.person.firstName,
        lastName: dto.person.lastName,
        companyName: dto.person.companyName,
        phone: dto.person.phone,
        mobilePhone: dto.person.mobilePhone,
        gender: dto.person.gender,
        serviceUserGroups: dto.serviceUserGroups || { data: {} }
      })
      .subscribe({
        next: (data: Person | any) => {
          this.addServiceUserOfToPerson(data.id, [dto.serviceInstanceId]);
        },
        error: (error) => {
          this.notificationService.showError('Fehler!', error.error.message);
        }
      });
  }

  deleteOnePerson(person: DeleteOnePersonPerson) {
    this.http
      .post(`${environment.apiBasePath}/party/user/delete`, {
        personId: person.personId,
        keycloakUser: person.keycloakUser
      })
      .subscribe({
        next: (data: Person | any) => {
          this.notificationService.showSuccess('Erfolg!', `Benutzer wurde gelöscht`);
          this.personQuery.refetch();
        },
        error: (error) => {
          this.notificationService.showError('Fehler!', error.error.message);
        }
      });
  }

  resendInviteTo(event: string) {
    this.http
      .post(`${environment.apiBasePath}/party/user/resendinvite`, {
        email: event
      })
      .subscribe((data) => {
        this.notificationService.showSuccess('Erfolg!', `Einladungs E-Mail wurde erneut gesendet`);
      });
  }

  async updatePersonRoles(event: UpdatePersonRoles) {
    let roles = [...event.keycloakRoles];

    const roleString = `Account Administrator`;

    if (event.isAdministrator && !roles.includes(roleString)) {
      await this.http.get(`${environment.apiBasePath}/party/user/${event.personId}/admin/add`).toPromise();
    }
    if (!event.isAdministrator && roles.includes(roleString)) {
      await this.http.get(`${environment.apiBasePath}/party/user/${event.personId}/admin/remove`).toPromise();
    }

    this.updatePerson({
      id: event.personId,
      email: event.email,
      serviceOwnerOf: event.serviceOwnerOf,
      companies: event.companies
    });
  }

  get scopes() {
    return this.oauthService.getGrantedScopes();
  }
}
