import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './party/register.component';

const routes: Routes = [
  // {
  //   path: 'profile',
  //   component: ProfileComponent,
  //   pathMatch: 'full'
  // },
  { path: 'profile', loadChildren: () => import('./party/party.module').then((m) => m.PartyModule) },
  {
    path: 'register',
    component: RegisterComponent,
    pathMatch: 'full'
  },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule) },
  { path: 'dcam', loadChildren: () => import('./dcam/dcam.module').then((m) => m.DcamModule) },
  { path: 'invoices', loadChildren: () => import('./invoices/invoices.module').then((m) => m.InvoicesModule) },
  { path: 'support', loadChildren: () => import('./support/support.module').then((m) => m.SupportModule) },
  { path: 'services', loadChildren: () => import('./services/services.module').then((m) => m.ServicesModule) },
  {
    path: 'account-management',
    loadChildren: () => import('./account-management/account-management.module').then((m) => m.AccountManagementModule)
  },
  {
    path: 'organization',
    loadChildren: () => import('./organization/organization.module').then((m) => m.OrganizationModule)
  },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule) },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
