<ui-headline
  [heading]="'Profil - ' + (partyService.person$ | async)?.firstName + ' ' + (partyService.person$ | async)?.lastName"
  [subheading]="(partyService.person$ | async)?.email + ''"
></ui-headline>
<div
  class="grid grid-cols-1 gap-8 mt-8 2xl:grid-cols-12 items-start"
  *ngIf="partyService.currentTenant$ | async as _currentTenant"
>
  <div ui-card class="flex flex-col gap-8 2xl:col-span-9">
    <ui-headline
      [heading]="'Zwei-Faktor-Authentifizierung'"
      [subheading]="'Hier können Sie Ihre 2FA Einstellungen verwalten.'"
      size="lg"
    ></ui-headline>
    <div class="gap-8 grid lg:grid-cols-2 gird-cols-1">
      <div class="flex flex-col">
        <h2 class="font-semibold">Was ist 2FA?</h2>
        <p class="mb-6">
          Das 2FA Verfahren ergänzt den Login-Prozess um die Eingabe einer Zahl, die von einer Authenticator App erzeugt
          wird. So wird die Sicherheit Ihres TelemaxX Kundencenter-Accounts erhöht, da der Login neben Ihrem
          Benutzernamen & Passwort noch der Besitz des Gerätes bzw. der Authenticator App mittels der Zahl nachgewiesen werden muss.
        </p>
        <h2 class="font-semibold">Welche Authenticator Apps werden unterstützt?</h2>
        <p class="mb-6">
          Generell sind alle Authenticator Apps verwendbar, die das TOTP-Verfahren unterstützen. Dies sind unter anderem
          FreeOTP, Microsoft Authenticator, Google Authenticator, Authy.
        </p>
        <h2 class="font-semibold">Was geschieht, wennn das letzte registrierte Gerät gelöscht wird?</h2>
        <p>
          Durch das entfernen des letzten registrierten Gerätes wird die 2FA-Authentifizierung für Ihren Account wieder deaktiviert. Der Login erfolgt dann nur noch per E-Mail und Passwort.<br />
          Falls Ihr Account Administrator die Verwendung von 2FA verpflichtend gesetzt hat, so können Sie zwar Ihr letztes Gerät entfernen, aber beim nächsten Login werden Sie zur Registrierung eines neuen Gerätes gezwungen.
        </p>
      </div>
      <div class="flex flex-col gap-4">
        <h2 class="font-medium">Wie richte ich 2FA ein?</h2>
        <span
          >(1) Falls Sie noch keine Authenticator App besitzen, laden Sie sich die gewünschte App auf Ihr Smartphone
          herunter.</span
        >
        <span>(2) Klicken Sie auf "2FA aktivieren & einrichten".</span>
        <span>(3) Scannen Sie den angezeigten QR-Code.</span>
        <span>(4) "Bestätigen" klicken</span>
        <span>(5) Geben Sie einen Gerätebezeichner ein, der das Geräte für Sie erkennbar kennzeichnet.</span>
        <span
          >(6) Geben Sie den 6-stelligen Code aus der App nun in das Feld Code ein und drücken Sie erneut
          "Bestätigen".</span
        >
        <span
          >(7) Möglicherweise bietet die App die Möglichkeit zur Einrichtung eines Backups oder eines
          Wiederherstellungscodes an. Folgen Sie hierzu den Anweisungen der App.</span
        >
      </div>
    </div>
    <hr class="w-full border-t border-gray-200" />
    <div class="grid lg:grid-cols-2 grid-cols-1" *ngIf="partyService.otpCredentials$.asObservable() | async as _otpCredentials">
      <div class="flex flex-col gap-4 border-gray-200 mt-8 lg:mt-0 lg:pr-8 pr-0 border-r-0 lg:border-r">
        <ui-headline
          heading="Status"
          size="sm"
        ></ui-headline>
        <div class="flex items-center gap-2" *ngIf="_otpCredentials[0].userCredentialMetadatas.length > 0 || _currentTenant.isOtpRequired">
          <span class="relative flex h-3 w-3">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-goblin-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-goblin-500"></span>
          </span>
          <span class="font-medium text-goblin-500">Ihre Zwei-Faktor-Authentifizierung ist aktiv</span>
        </div>
        <div class="flex items-center gap-2" *ngIf="_otpCredentials[0].userCredentialMetadatas.length === 0 && !_currentTenant.isOtpRequired">
          <span class="relative flex h-3 w-3">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
          </span>
          <span class="font-medium text-red-500">Ihre Zwei-Faktor-Authentifizierung ist nicht aktiv</span>
        </div>
        <p *ngIf="_otpCredentials[0].userCredentialMetadatas.length === 0">
          Ihr Login erfordert zur Zeit nur Ihre E-Mail und Passwort.<br />
          Klicken Sie auf 2FA aktivieren, um die Sicherheit Ihres Benutzerkontos zu erhöhen.
        </p>
        <p *ngIf="_otpCredentials[0].userCredentialMetadatas.length > 0">
          Hervoragend! Ihr Login erfordert neben Ihrer E-Mail, dem Passwort auch noch den OneTime-Code.
        </p>
        <div *ngIf="_currentTenant.isOtpRequired">
          <ui-info>
            <ui-icon [icon]="'information-circle'" class="w-5 h-5 shrink-0"></ui-icon>
            <p>Diese Einstellung wird von Ihren Account Administrator verwaltet.</p>
          </ui-info>
        </div>
      </div>
      <div class="flex flex-col ml-0 lg:ml-8 mt-8 lg:mt-0">
        <ui-headline
          heading="Registrierte 2FA Geräte / APPs"
          subheading="Diese Geräte sind momentan als zweiter Authentifizierungsfaktor zugelassen und registriert."
          size="sm"
        ></ui-headline>
        <div class="flex flex-col my-4" *ngIf="_otpCredentials[0].userCredentialMetadatas.length != 0">
          <div
            *ngFor="let device of _otpCredentials[0].userCredentialMetadatas"
            class="grid grid-cols-3 gap-4 border-t border-gray-200 first:border-t-0 py-2"
          >
            <div class="col-span-1 flex items-center gap-4 h-[2.125rem]">
              <ng-container *ngIf="deviceToRename?.credential?.id != device.credential.id">
                <ui-icon icon="device-phone-mobile" class="w-5 h-5"></ui-icon>
                <span class="font-medium">{{ device.credential.userLabel }}</span>
              </ng-container>
              <ng-container *ngIf="deviceToRename?.credential?.id === device.credential.id">
                <ui-forms-input [parentForm]="renameForm" [controlName]="'deviceLabel'"></ui-forms-input>
                <ui-button [icon]="'check'" [isOnlyIcon]="true" [color]="'goblin'" [size]="'xs'" (cclick)="renameDevice()"></ui-button>
              </ng-container>
            </div>
            <div class="col-span-2 flex items-center justify-between">
              <span class="text-gray-500">Registriert am {{ device.credential.createdDate | date }}</span>
              <ui-context-menu
                [contextMenu]="deviceToRename ? contextMenuCancel : contextMenuRename"
                (contextMenuClick)="contextMenuClick($event, device)"
              ></ui-context-menu>
            </div>
          </div>
        </div>
        <div
          class="w-full text-center my-8 font-medium text-sm"
          *ngIf="_otpCredentials[0].userCredentialMetadatas.length == 0"
        >
          Aktuell sind keine Geräte registriert
        </div>
        <div class="flex items-center gap-4" *ngIf="_otpCredentials[0].userCredentialMetadatas.length === 0">
          <ui-button [color]="'goblin'" type="secondary" (cclick)="partyService.configureTotp()"
            >2FA aktivieren & einrichten</ui-button
          >
        </div>
        <ui-button
          color="goblin"
          type="secondary"
          *ngIf="_otpCredentials[0].userCredentialMetadatas.length != 0"
          (cclick)="this.partyService.configureTotp()"
          >Weiteres Gerät registrieren</ui-button
        >
      </div>
    </div>
  </div>
  <div ui-card class="flex flex-col items-center gap-8 2xl:col-span-3">
    <ui-headline
      [heading]="'Login-Flow mit 2FA'"
      [subheading]="'Mit aktiven 2FA sieht Ihr Login-Flow wie folgt aus:'"
      size="lg"
      class="w-full"
    ></ui-headline>
    <span class="font-medium w-full">Schritt 1:</span>
    <img src="/assets/help/otpstep1.png" class="w-full h-auto max-w-xs" />
    <span class="font-medium w-full">Schritt 2:</span>
    <img src="/assets/help/otpstep2.png" class="w-full h-auto max-w-xs" />
  </div>
</div>
