import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { first } from 'rxjs';
import { PartyService } from '../party/party.service';

@Component({
  selector: 'tx-portal-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  constructor(private fb: FormBuilder, public partyService: PartyService) {}

  settingsForm = this.fb.group({
    force2fa: false
  });

  ngOnInit(): void {
    this.partyService.currentTenant$.pipe(first()).subscribe((c) => {
      this.settingsForm.setValue(
        {
          force2fa: c?.isOtpRequired ?? false
        },
        {
          emitEvent: false
        }
      );
    });
    this.settingsForm.valueChanges.subscribe((s) => {
      this.partyService.updateCompanyOTPSettings();
    });
  }
}
